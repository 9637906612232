import { OCCUPATIONAL_HEALTH_USER, ALLOWED_ROLES } from '@/constants'
import { defineStore } from 'pinia'

const isOCUUser = (user) => {
  return user && user.roles && user.roles.some((role) => role.role === OCCUPATIONAL_HEALTH_USER)
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: undefined
  }),
  getters: {
    isAuthenticated: (state) => Boolean(state.user),
    getRoles: (state) => (state.user ? state.user.roles.map((roleOrg) => roleOrg.role) : []),
    isOccupationalHealthUser: (state) => isOCUUser(state.user),
    getUser: (state) => state.user,
    getFullname: (state) => state.user.firstName + ' ' + state.user.lastName,
    getHasRightsToPira: (state) =>
      state.user.roles.some((role) => ALLOWED_ROLES.includes(role.role))
  },
  actions: {
    login(user) {
      this.user = user
    }
  }
})
