<template>
  <header ref="header" class="hide-in-print" :class="{ 'header--too-large': headerTooLarge }">
    <div class="header-container">
      <div class="header-background">
        <div class="logo-container">
          <button
            ref="navigationMenuToggle"
            :aria-expanded="`${showNavigation}`"
            :aria-label="$t('frontPage.openMenu')"
            aria-controls="navigation-menu"
            class="button button--text header__open-navigation"
            @click.prevent="toggleNavigationVisibility()"
          >
            <font-awesome-icon :icon="['fas', 'bars']" />
          </button>
          <span class="logo-link">
            <router-link id="pira-logo-link" :to="{ name: 'frontpage' }"> PIRA™ </router-link>
          </span>
        </div>
        <nav
          id="navigation-menu"
          ref="navigationMenu"
          :aria-label="$t('header.mainMenu')"
          :class="{ 'nav--open': showNavigation }"
        >
          <ul>
            <li v-if="showLinkToFrontPage">
              <accessible-link :to="{ name: 'frontpage' }" exact @click="closeMenus">
                {{ $t('header.frontpageLink') }}
              </accessible-link>
            </li>
            <li v-if="getCurrentSmeOfficeId !== undefined">
              <accessible-link
                :to="{ name: 'riskmanagement', params: { officeid: getCurrentSmeOfficeId } }"
                @click="closeMenus"
              >
                {{ $t('header.riskManagementLink') }}
              </accessible-link>
            </li>
            <li v-if="getCurrentSmeOfficeId !== undefined">
              <accessible-link
                :to="{ name: 'documents', params: { officeid: getCurrentSmeOfficeId } }"
                @click="closeMenus"
              >
                {{ $t('header.documentsLink')
                }}{{ 1 > getAmountOfNewDocuments ? '' : ` (${getAmountOfNewDocuments})` }}
              </accessible-link>
            </li>
            <li v-if="isAuthenticated" class="hide-in-tablet hide-in-desktop">
              <router-link :to="{ name: 'logout' }">
                {{ $t('header.logoutLink') }}
              </router-link>
            </li>
            <li v-if="!isAuthenticated" class="hide-in-tablet hide-in-desktop" @click="login">
              {{ $t('header.loginLink') }}
            </li>
          </ul>
        </nav>
      </div>
      <button
        v-if="isAuthenticated"
        :aria-label="$t('frontPage.openMenu')"
        :aria-expanded="`${showUserMenu}`"
        aria-controls="user-menu"
        class="button button--text header__open-user-menu"
        @click.prevent.stop="toggleUserMenuVisibility()"
      >
        {{ getFullname }}
      </button>
      <router-link
        v-if="!isAuthenticated"
        id="loginLink"
        :to="{ name: 'login' }"
        class="header__open-user-menu"
        @click.prevent="toggleUserMenuVisibility()"
      >
        {{ $t('header.loginLink') }}
      </router-link>
      <div ref="userMenu" class="user-menu" :class="{ 'user-menu--open': showUserMenu }">
        <ul>
          <li>
            <router-link :to="{ name: 'logout' }">
              {{ $t('header.logoutLink') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { EventBus } from '@/bus'
import debounce from 'lodash.debounce'
import AccessibleLink from '@/components/common/AccessibleLink.vue'
import focusMixin from '@/components/mixins/focusMixin'
import { mapState } from 'pinia'
import { useSmeOfficeStore } from '@/store/smeOfficeStore'
import { useDocumentStore } from '@/store/documentStore'
import { useUserStore } from '@/store/userStore'
import { APP_BASE_URL, BUS_BASE_URL } from '@/constants';

const MAX_HEIGHT_ELEMENT_IS_VISIBLE = 0.39

export default {
  name: 'PiraHeader',
  components: { AccessibleLink },
  mixins: [focusMixin],
  data: function () {
    return {
      showNavigation: false,
      showUserMenu: false,
      headerTooLarge: false
    }
  },
  computed: {
    ...mapState(useDocumentStore, ['getAmountOfNewDocuments']),
    ...mapState(useUserStore, ['isAuthenticated', 'getFullname', 'isOccupationalHealthUser']),
    ...mapState(useSmeOfficeStore, [
      'getOwnSmeOffices',
      'getIsSmeDataLoading',
      'getCurrentSmeOfficeId'
    ]),
    showLinkToFrontPage() {
      return (
        this.isAuthenticated &&
        ((!this.getIsSmeDataLoading &&
          !this.isOccupationalHealthUser &&
          this.getOwnSmeOffices.length <= 1) ||
          this.$route.name !== 'frontpage')
      )
    }
  },
  watch: {
    getCurrentSmeOfficeId: function (newValue) {
      if (newValue) {
        const documentStore = useDocumentStore()
        documentStore.getDocumentList({ organizationId: newValue })
      }
    }
  },
  created() {
    window.addEventListener(
      'resize',
      debounce(() => {
        this.headerTooLarge =
          this.$el.clientHeight / window.innerHeight > MAX_HEIGHT_ELEMENT_IS_VISIBLE
      }, 200)
    )
    EventBus.$on('close-header-menus', this.closeMenus)
  },
  unmounted() {
    EventBus.$off('close-header-menus', this.closeMenus)
  },
  methods: {
    login() {
      window.location.href = `${BUS_BASE_URL}/login-pira?RelayState=${APP_BASE_URL}/new-login`
    },
    toggleCloseMenusEvent(show) {
      if (show) {
        this.$nextTick(() => window.addEventListener('click', this.closeMenus))
      } else {
        window.removeEventListener('click', this.closeMenus)
      }
    },
    toggleNavigationVisibility() {
      this.showNavigation = !this.showNavigation
      this.toggleCloseMenusEvent(this.showNavigation)
    },
    toggleUserMenuVisibility() {
      this.showUserMenu = !this.showUserMenu
      this.toggleCloseMenusEvent(this.showUserMenu)
    },
    closeMenus(e) {
      const noCloseElements = [
        this.$refs.navigationMenu,
        this.$refs.navigationMenuToggle,
        this.$refs.userMenu
      ]
      if (!e || noCloseElements.every((elem) => !elem.contains(e.target))) {
        this.toggleCloseMenusEvent(false)
        this.showUserMenu = false
        this.showNavigation = false
      }
    },
    setFocus() {
      // Don't change focus if it's already in the header
      if (!this.$refs.header.contains(document.activeElement)) {
        // Call the overridden setFocus method which claims focus to the first focusable item in header
        focusMixin.methods.setFocus.bind(this)()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/componentimport';

header {
  @include breakpoint(mobile) {
    left: 0;
    position: fixed;
    top: 0;
  }

  &.header--too-large {
    opacity: 0;

    a {
      display: none;
    }

    pointer-events: none;
  }
}
</style>
