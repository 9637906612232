import { ALLOWED_ROLES, APP_BASE_URL, BUS_BASE_URL } from '@/constants'
import { createRouter, createWebHistory, RouterView } from 'vue-router'
import { getUser } from './api/user'
import { useUserStore } from './store/userStore'
import { useSmeOfficeStore } from './store/smeOfficeStore.js'
import { useRiskManagementStore } from './store/riskManagementStore'

const hasRequiredRole = (roles) => roles.some((role) => ALLOWED_ROLES.includes(role))

const updateCurrentSme = (to) => {
  if (to.params.officeid) {
    const smeOfficesStore = useSmeOfficeStore()
    smeOfficesStore.updateCurrentSme(to.params.officeid)
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'frontpage',
      meta: {
        title: 'titles.frontPage'
      },
      component: () => import('@/components/FrontpageRoute.vue'),
      beforeEnter: (to, from, next) => {
        const smeOfficesStore = useSmeOfficeStore()
        const ownSmeOffices = smeOfficesStore.getOwnSmeOffices
        if (ownSmeOffices && ownSmeOffices.length !== 1) {
          // clear current SME when there's more than one office (if only one, it's always the current)
          const smeOfficesStore = useSmeOfficeStore()
          smeOfficesStore.updateCurrentSme(undefined)
        }
        next()
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: RouterView,
      meta: {
        title: 'titles.logout'
      },
      beforeEnter() {
        const riskManagementStore = useRiskManagementStore()
        riskManagementStore.clearRiskFilters()

        if (localStorage.sessionLoginTime) {
          const sessionLoginTime = localStorage.sessionLoginTime
          localStorage.removeItem('sessionLoginTime')
          window.location.href = `${BUS_BASE_URL}/logout?pira-login-timestamp=${sessionLoginTime}`
        } else {
          window.location.href = `${BUS_BASE_URL}/logout`
        }
        return false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: RouterView,
      meta: {
        title: 'titles.login'
      },
      beforeEnter() {
        window.location = `${BUS_BASE_URL}/login-pira?RelayState=${APP_BASE_URL}/new-login`
        return false
      }
    },
    {
      path: '/new-login',
      name: 'new-login',
      component: RouterView,
      meta: {
        title: 'titles.login'
      },
      beforeEnter(to, from, next) {
        const riskManagementStore = useRiskManagementStore()
        riskManagementStore.clearRiskFilters()
        localStorage.sessionLoginTime = new Date().toISOString()
        next({
          name: 'frontpage'
        })
      }
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        title: 'titles.register'
      },
      // Pass the query string values as props to TTHRegisterForm
      props: (route) => ({ invitationKey: route.query.key, email: route.query.email }),
      component: () => import('@/components/register/RegisterWithInvitation.vue')
    },
    {
      path: '/register/success',
      name: 'register.success',
      meta: {
        title: 'titles.register'
      },
      component: () => import('@/components/register/RegisterSuccess.vue')
    },
    {
      path: '/riskmanagement/:officeid',
      name: 'riskmanagement',
      meta: {
        requiresAuth: true,
        title: 'titles.riskManagement'
      },
      component: () => import('@/components/riskmanagement/RiskManagement.vue'),
      beforeEnter: [updateCurrentSme]
    },
    {
      path: '/riskmanagement/:officeid/preview',
      name: 'riskmanagement.preview',
      meta: {
        requiresAuth: true,
        title: 'titles.preview'
      },
      component: () => import('@/components/riskmanagement-preview/RiskManagementPreview.vue'),
      beforeEnter: [updateCurrentSme]
    },
    {
      path: '/documents/:officeid',
      name: 'documents',
      meta: {
        requiresAuth: true,
        title: 'titles.documents'
      },
      component: () => import('@/components/documents/DocumentsRoute.vue'),
      beforeEnter: [updateCurrentSme]
    },

    {
      path: '/cookiepolicy',
      name: 'cookiepolicy',
      meta: {
        title: 'titles.cookiepolicy'
      },
      component: () => import('@/components/CookiePolicy.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ],

  scrollBehavior() {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  try {
    const userStore = useUserStore()
    const smeOfficeStore = useSmeOfficeStore()
    getUser().then(async (user) => {
      if (
        !Object.values(smeOfficeStore.getSmeOffices).length ||
        userStore.getUser?.uuid !== user.uuid
      ) {
        const riskManagementStore = useRiskManagementStore()
        riskManagementStore.loadRiskFilters()
        userStore.login(user)
        smeOfficeStore.updateSmeOfficeData({ user, filters: {} }, { root: true })
      }
      userStore.login(user)
      const isNotAuthenticated = !userStore.isAuthenticated
      const isAuthenticatedWithoutRole =
        userStore.isAuthenticated && !hasRequiredRole(userStore.getRoles)
      if (
        to.meta.auth &&
        // Route requires authentication and the user has not authenticated
        (isNotAuthenticated ||
          // Route requires authentication, the user is authenticated but does not have the necessary roles
          isAuthenticatedWithoutRole)
      ) {
        next({
          name: 'frontpage'
        })
      } else {
        next()
      }
    })
  } catch (error) {
    next({
      name: 'frontpage'
    })
  }
})

export default router
