<template>
  <v-app id="app" @keyup.tab.once="registerTab()">
    <v-main>
      <cookiepro />
      <PiraHeader v-if="!isIE" ref="header" />
      <v-container class="container py-xs-4 px-xs-2 pa-sm-7">
        <router-view v-if="!isIE" />
        <AppBlocked v-else v-once />
      </v-container>
      <PiraFooter v-if="!isIE" />
      <VueAnnouncer />
      <GenericErrorModal />
    </v-main>
  </v-app>
</template>

<script>
import { EventBus } from '@/bus'
import unescape from 'lodash.unescape'
import PiraHeader from '@/components/PiraHeader.vue'
import PiraFooter from '@/components/PiraFooter.vue'
import AppBlocked from './AppBlocked.vue'
import CookieproComponent from '@/components/cookiepro/Cookiepro.vue'
import { env } from '@/constants'
import GenericErrorModal from '@/components/error/GenericErrorModal.vue'
import { mapState, mapStores } from 'pinia'
import { useUserStore } from '@/store/userStore'
import { useSmeOfficeStore } from '@/store/smeOfficeStore'
import { useDocumentStore } from '@/store/documentStore'

const isIE = navigator.userAgent.match(/MSIE [0-9][0.]|Trident\/[0-6]/g)

export default {
  name: 'App',
  components: {
    PiraHeader,
    PiraFooter,
    AppBlocked,
    GenericErrorModal,
    cookiepro: CookieproComponent
  },
  data() {
    return {
      isIE
    }
  },
  computed: {
    ...mapStores(useDocumentStore),
    ...mapState(useUserStore, ['isAuthenticated']),
    ...mapState(useSmeOfficeStore, ['getCurrentSmeOfficeId'])
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (val, previous) {
        const org = this.getCurrentSmeOfficeId
          ? useSmeOfficeStore().getSmeOfficeName(this.getCurrentSmeOfficeId)
          : null
        const page = val.meta.title
        const title = org
          ? this.$t('titles.withOrg', { org, page })
          : page
            ? this.$t('titles.withoutOrg', { page })
            : this.$t('titles.withoutOrgAndPage')
        document.title = unescape(title)
        previous &&
          this.$nextTick(() => {
            this.announceNavigation(page)
            // After each subsequent navigation, move focus to the first header element
            EventBus.$emit('set-focus', this.$refs.header)
          })
      }
    }
  },
  created() {
    if (env === 'staging') {
      document.title = 'PIRA™-testipalvelu'
    }
    if (this.isAuthenticated && this.getCurrentSmeOfficeId) {
      this.documentStore.getDocumentList({
        organizationId: this.getCurrentSmeOfficeId
      })
    }
  },
  methods: {
    registerTab() {
      document.body.classList.add('user-tabbing')
    },
    announceNavigation(page) {
      this.$announcer.set(this.$t('titles.announcement', { page }), 'assertive')
    }
  }
}
</script>

<style lang="scss">
// global styles
@import '../sass/pira';
</style>
