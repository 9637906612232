import { defineStore } from 'pinia'

export const useGenericErrorStore = defineStore('genericError', {
  state: () => ({
    errorMessage: {}
  }),
  getters: {
    isShowErrorMessage: (state) => Object.keys(state.errorMessage).length >= 1,
    getErrorMessage: (state) => state.errorMessage
  },
  actions: {
    addErrorMessage(message) {
      this.errorMessage = message
    },
    deleteErrorMessage() {
      this.errorMessage = {}
    }
  }
})
