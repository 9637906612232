<template>
  <div />
</template>

<script>
import { COOKIE_BASE_PRO_URL } from '@/constants'

function getDataDomain() {
  return COOKIE_BASE_PRO_URL
}

export default {
  name: 'CookieproComponent',
  mounted() {
    const cookieproScript = document.createElement('script')
    cookieproScript.setAttribute(
      'src',
      'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
    )
    cookieproScript.setAttribute('data-document-language', 'true')
    cookieproScript.setAttribute('type', 'text/javascript')
    cookieproScript.setAttribute('charset', 'UTF-8')
    cookieproScript.setAttribute('data-domain-script', getDataDomain())
    document.head.appendChild(cookieproScript)
  }
}
</script>
