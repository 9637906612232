import { isBefore, parseISO } from 'date-fns'
import { uploadDocument } from '@/api/documents'
import { getDocumentList } from '@/api/documents'
import { deleteDocument } from '@/api/documents'
import { defineStore } from 'pinia'
import { useUserStore } from './userStore'

export const useDocumentStore = defineStore('document', {
  state: () => ({
    documents: {}
  }),
  getters: {
    getDocuments: (state) => Object.values(state.documents),
    getAmountOfNewDocuments: (state) => {
      const userStore = useUserStore()
      const lastLoginTime = userStore.getUser.lastPiraLoginTime
      if (lastLoginTime) {
        return Object.values(state.documents).filter(
          (document) =>
            document.createdBy.uuid !== userStore.getUser.uuid &&
            isBefore(parseISO(lastLoginTime), parseISO(document.createdAt))
        ).length
      } else {
        return 0
      }
    }
  },
  actions: {
    addDocument(document) {
      this.documents[document.id] = document
    },
    async uploadDocument(document) {
      const response = await uploadDocument(document.organizationId, document.fileData)
      this.documents[response.data.id] = response.data
    },
    async getDocumentList(document) {
      const response = await getDocumentList(document.organizationId)
      this.documents = {}
      response.data.forEach((element) => {
        this.documents[element.id] = element
      })
    },
    async deleteDocument(document) {
      await deleteDocument(document.organizationId, document.documentId)
      this.documents = Object.keys(this.documents)
        .filter((key) => key !== document.documentId)
        .reduce((obj, key) => {
          obj[key] = this.documents[key]
          return obj
        }, {})
    }
  }
})
