export default {
  // Page titles
  titles: {
    default: 'PIRA™',
    frontPage: 'Etusivu',
    login: 'Kirjautuminen',
    logout: 'Uloskirjautuminen',
    register: 'Rekisteröidy',
    riskManagement: 'Riskienarviointi',
    preview: 'Esikatselu',
    documents: 'Dokumentit',
    withOrg: "@:{page} {'|'} {org} {'|'} @:titles.default",
    withoutOrg: "@:{page} {'|'} @:titles.default",
    withoutOrgAndPage: '@:titles.default',
    announcement: 'Siirrytty @:{page}',
    cookiepolicy: 'Evästekäytännöt'
  },

  // Header related translations
  header: {
    frontpageLink: 'Etusivu',
    riskManagementLink: 'Riskien arviointi ja työpaikkaselvitys',
    documentsLink: 'Dokumentit',
    logoutLink: 'Kirjaudu ulos',
    loginLink: 'Kirjaudu sisään',
    title: 'PIRA™-palvelu',
    imageText: 'Työkalu työterveyshuollon ja yrityksen työterveysyhteistyöhön',
    mainMenu: 'Päävalikko',
    cookiepolicy: 'Evästekäytännöt'
  },
  // Footer related translations
  footer: {
    termsOfService: 'Käyttöehdot',
    generalTerms: 'Työterveyslaitoksen sähköisten palveluiden yleiset ehdot',
    dataProtectionPrinciples:
      'Työterveyslaitoksen sähköisten palveluiden tiedonsuojauksen periaatteet',
    piraTerms: 'PIRA™-palvelun erityisehdot',
    privacyStatement: 'PIRA™-palvelun tietosuojailmoitus',
    accessibilityStatement: 'PIRA™-palvelun saavutettavuusseloste',
    fioh: 'Työterveyslaitos',
    pira: 'PIRA™-palvelu',
    address:
      'PL 40<br >00032 Työterveyslaitos<br >Puh. 0304741<br ><a href="http://www.ttl.fi" target="_blank">www.ttl.fi</a>'
  },
  // User related translations
  user: {
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    email: 'Sähköposti',
    password: 'Salasana',
    passwordAgain: 'Salasana uudelleen',
    title: 'Tehtävänimike',
    employer: 'Työnantaja',
    streetAddress: 'Postiosoite',
    postalCode: 'Postinumero',
    postalAddress: 'Postitoimipaikka',
    phoneNumber: 'Puhelinnumero'
  },
  // Landing page related translations
  landingPage: {
    title: 'Työterveyslaitos lopettaa PIRA-palvelun tarjoamisen 31.12.2024 jälkeen',
    text: 'PIRA-palvelu ei ole enää tämän jälkeen saatavilla. Löydät muut palvelumme verkkosivuiltamme <a href="http://www.ttl.fi" target="_blank">www.ttl.fi</a>'
  },

  // Organization related translations
  organization: {
    organizationsList: 'Yrityksen valinta'
  },

  // Register form
  register: {
    title: 'Rekisteröidy palveluun',
    formTitle: 'Omat tiedot',
    acceptTerms: 'Hyväksyn käyttöehdot',
    allowMarketing:
      'Kyllä, haluan vastaanottaa tietoa myös muista Työterveyslaitoksen tapahtumista ja palveluista',
    submit: 'Rekisteröidy',
    registerSuccess: 'Tilin luominen onnistui.',
    goToLogin: 'Siirry kirjautumaan tästä',

    invalid: {
      title: 'Rekisteröitymisen aloittaminen epäonnistui',
      explanation: 'Voimassa olevaa kutsua ei löytynyt. Tämä voi johtua mm. seuraavista asioista',
      reason1: 'Rekisteröitymislinkkisi on jo käytetty',
      reason2: 'Rekisteröitymislinkkisi on virheellinen',
      footer: 'Ole hyvä ja tarkasta, että rekisteröitymislinkkisi on varmasti oikein.',
      generic: 'Tapahtui virhe.',
      details: 'Uuden käyttäjän tiedot on syötetty väärin. Tarkasta tiedot ja yritä uudelleen.'
    }
  },

  // SME company selection table for TTH user
  SMESelectionTable: {
    headingName: 'Nimi',
    headingBusinessId: 'Y-Tunnus',
    headingCity: 'Kaupunki',
    headingAddress: 'Katuosoite',
    headingLastContentAddedBySme: 'Yritys lisännyt sisältöä',
    unknownValue: 'Ei tietoa',
    filterPlaceholder: 'Hae yritystä',
    paginationAll: 'Kaikki',
    paginationRowAmount: 'Rivejä sivulla',
    noData: 'Tietoja ei saatavissa'
  },
  frontPage: {
    openMenu: 'Avaa valikko',
    start: 'Aloita',
    title: 'Tervetuloa PIRA™-palveluun!',
    description:
      'PIRA™-palvelu on työkalu lain edellyttämän työturvallisuus- ja terveysriskien arvioinnin ja työpaikkaselvityksen tekoon yhteistyössä työterveyshuollon kanssa.',
    motivational:
      'Työpaikan tilanne on hyvä tarkistaa työterveyshuollon kanssa säännöllisesti. Palvelusta voit helposti näyttää työpaikkasi tilanteen myös työsuojelutarkastajalle.',
    stagingOpeningTimes: 'Testiversio on suljettu arkisin klo 21 - 06 ja viikonloppuisin kokonaan.',
    feedback:
      "Palautetta palvelusta, kysyttävää, ongelmia? Ota yhteyttä Työterveyslaitoksen asiakastukeen pira{'@'}ttl.fi. Palvelemme arkisin toimistoaikana, poislukien loma-ajan 13.7. - 6.8.2018.",
    helpTitle: 'Kysyttävää?',
    help: 'Jos tarvitset apua, ota tarvittaessa yhteyttä oman työterveyshuoltosi yhteyshenkilöön.',
    guide: {
      title: 'Näin käytät palvelua',
      section1: 'Mene sivulle',
      section1Link: 'Riskien arviointi ja työpaikkaselvitys',
      section1MultiplePK: 'Valitse yritys kohdasta',
      section2:
        'Aloita riskien arviointi valitsemalla esiintyykö kyseistä asiaa työpaikalla vai ei. Tavoitteena on tunnistaa kaikki merkittävimmät turvallisuuspuutteet, jotka saattavat aiheuttaa vakavaa haittaa ihmisten terveydelle tai turvallisuudelle.',
      section3:
        'Mikäli tarkasteltavaa asiaa esiintyy, kirjoita siitä havainto. Voit lisätä jokaiselle havainnolle oman osion.',
      section4: 'Arvioi asian tila liikennevalojen avulla.',
      section5:
        'Voit myös lisätä riskien arviointiin liittyviä tiedostoja Dokumentit-sivulle, kun käytät palvelua tietokoneella tai tabletilla. Myös työterveyshuoltosi voi lisätä sinne tiedostoja.',
      section6:
        'Työterveyshuolto käy palveluun kirjatut tiedot läpi ennen mahdollista työpaikkakäyntiään. Etukäteen kirjatut tiedot nopeuttavat yhteistä arviointia työpaikkakäynnillä.',
      section7:
        'Työterveyshuolto arvioi ja kirjoittaa palveluun tehtyjen havaintojen terveydellisen merkityksen ja antaa tarvittaessa toimenpide-ehdotuksia. Työterveyshuolto voi kirjata palveluun myös omia havaintojaan.',
      section8:
        'Työpaikkakäynnin jälkeen täydennätte molemmat tietoja palveluun tarpeen mukaan. Muista kirjata palveluun työpaikkasi suunnitellut toimenpiteet. Kirjaa myös, kun toimenpiteet on tehty. Lisää uusia havaintoja ja kirjaa toimenpiteitä jatkuvana toimintana, jotta palvelussa on aina ajantasaisin tieto työpaikkasi tilanteesta.',
      section2Subsections: {
        section1: 'Mitä vaaroja työssä esiintyy?',
        section2: 'Mistä vaara johtuu, mikä sen aiheuttaa?',
        section3: 'Missä vaara esiintyy?',
        section4: 'Kuka tai ketkä ovat vaaralle alttiina?'
      },
      section4Subsections: {
        section1Title: 'Kohtalainen riski',
        section2Title: 'Merkittävä riski',
        section3Title: 'Sietämätön riski',
        section1Content:
          'On ryhdyttävä toimenpiteisiin riskin pienentämiseksi. Toimenpiteet tulee mitoittaa ja aikatauluttaa järkevästi. Jos riskiin liittyy erittäin vakavia seurauksia, on selvitettävä tarkemmin tapahtuman todennäköisyys.',
        section2Content:
          'Riskin pienentäminen on välttämätöntä. Toimenpiteet tulee aloittaa nopeasti. Riskialtis toiminta pitää saada loppumaan nopeasti, eikä sitä saa aloittaa, ennen kuin riskiä on pienennetty.',
        section3Content:
          'Riskin poistaminen on välttämätöntä. Toimenpiteet tulee aloittaa välittömästi. Riskialtis toiminta tulee keskeyttää eikä sitä saa aloittaa, ennen kuin riski on poistettu.'
      }
    },
    guideOH: {
      title: 'Näin käytät palvelua',
      section1: 'Valitse yritys kohdasta <span class="semibold">Yrityksen valinta</span>.',
      section2:
        'Tutustu yrityksen palveluun kirjaamiin tietoihin ennen mahdollista työpaikkakäyntiä. Täydennä tietoja mahdollisuuksien mukaan omilla havainnoilla, terveydellisillä merkityksillä ja toimenpide-ehdotuksilla. Voit myös lisätä yrityksen Dokumentit-sivulle työpaikkaselvitykseen liittyviä tiedostoja, kun käytät palvelua tietokoneella tai tabletilla. Myös yritys voi lisätä sinne tiedostoja.',
      section3:
        'Käykää kaikki kohdat läpi yhdessä yrityksen kanssa. Merkitkää palveluun, esiintyykö tarkasteltavaa asiaa työpaikalla vai ei. Mikäli esiintyy, kirjoittakaa siitä havainto ja arvioikaa samalla asian tilaa. Voitte luoda jokaiselle havainnolle oman osion. Kirjatkaa palveluun myös suunnitellut toimenpiteet.',
      section4: 'Työpaikkakäynnin jälkeen täydennätte molemmat tietoja palveluun tarpeen mukaan.',
      section5:
        'Kirjoita yhteenveto työpaikan tilanteesta kohtaan <span class="semibold">Työterveyshuollon yhteenveto</span>.',
      section6:
        'Jos käytät palvelua tietokoneella tai tabletilla, voit tarkastella kaikkia tietoja kohdassa <span class="semibold">Esikatselu</span>. Voit tulostaa tiedot ja tallentaa ne tiedostoon samasta näkymästä.'
    },
    contact: {
      title: 'OTA YHTEYTTÄ',
      bodyPart1:
        'Jos haluat lisätä palveluun yrityksen, lisätä tai poistaa käyttäjiä, tai jos havaitset virhetilanteen, ota yhteyttä asiakastukeen ',
      bodyLink: "pira{'@'}ttl.fi.",
      bodyPart2: 'Palvelemme arkisin toimistoaikana.'
    },
    serviceShutdown: {
      info: 'Työterveyslaitos lopettaa PIRA-palvelun tarjoamisen 31.12.2024 jälkeen',
      text: 'Palvelu ei ole enää tämän jälkeen saatavilla. Huomaa, että palvelu voidaan sulkea jo ennen tätä ajankohtaa, mikäli työterveyshuolto on näin sopinut Työterveyslaitoksen kanssa. Kysy palvelun sulkeutumisajasta yhteyshenkilöltäsi. PIRA-palvelusta ei saa tietoja ulos enää palvelun sulkeuduttua. Ota tarvittavat tiedot ajoissa talteen.<br /><br /> Työterveyslaitos haluaa kiittää palvelun käyttäjiä ja pahoittelee palvelun sulkemisesta aiheutuvaa haittaa.'
    }
  },
  riskManagementFilters: {
    title: 'Rajaa tietoja ajanjaksolta',
    startDate: 'Alkaen',
    endDate: 'Päättyen',
    datePlaceholder: 'pp.kk.vvvv',
    invalidDateError: 'Virhe valitessa päivää',
    invalidDateRangeError: 'Alkamispäivän täytyy olla ennen päättymispäivää',
    applyButtonText: 'Näytä rajaus',
    clearButtonText: 'Poista rajaus',
    filtersActiveText: 'Tietojen rajaus käytössä',
    filtersClearedText: 'Tietojen rajaus pois käytöstä',
    activeFilterDescription: 'Rajaus koskee työpaikkaselvitystä ja riskien arviointia',
    filtersActiveInPreviewText: 'Ajanjaksolta'
  },
  riskManagement: {
    addTitle: 'Lisää uusi osio',
    title: 'Riskien arviointi ja työpaikkaselvitys',
    notInUse: 'Ei esiinny',
    inUse: 'Esiintyy',
    addMessage: 'Lisää',
    messageSuccess: 'Lisätty',
    messageSuccessLong: 'Viesti lisätty',
    messageError: 'Virhe',
    newMessagePlaceholder: 'Kirjoita tähän',
    srCue: 'Tällä ikonilla kuvatun riskitekijän tila on: @:{0}',
    newMessages: 'Uusia viestejä',
    inOrder: 'Kunnossa',
    inOrderDescription: 'Toimenpiteitä ei tarvita.',
    requiresFollowUp: 'Vaatii seuraamista',
    requiresFollowUpDescription:
      'Vähäinen riski - toimenpiteitä ei välttämättä tarvita. Tilannetta tulee seurata, jotta riski pysyy hallinnassa.',
    requiresImprovement: 'Vaatii toimenpiteitä',
    newContent: 'uutta sisältöä',
    openDetails: 'Avaa lisätiedot',
    allowEditModalTitle: 'Haluatko poistaa päivämäärällä tehdyn rajauksen?',
    allowEditModalText: 'Sinun tulee ensin poistaa rajaus halutessasi muokata tai lisätä tietoja.',
    allowEditModalButton: 'Poista rajaus',
    noContentForDateSpan: 'Ei sisältöä rajatulla aikavälillä',
    observationMessages: {
      actionProposal: {
        title: 'Toimenpide',
        defaultText:
          'Työterveyshuolto kirjaa tänne toimenpide-ehdotukset. Työpaikka kirjaa suunnitellut ja tehdyt toimenpiteet.',
        placeholder: 'Kirjoita toimenpide-ehdotus tai toimenpide tähän',
        buttonText: 'Tallenna ehdotus tai toimenpide'
      },
      observation: {
        title: 'Havainto',
        defaultText: 'Työpaikka ja työterveyshuolto kirjaavat tänne tehdyt havainnot.',
        placeholder: 'Kirjoita havainto tähän',
        buttonText: 'Tallenna havainto'
      },
      healthImplication: {
        title: 'Terveydellinen merkitys',
        defaultText: 'Työterveyshuolto kirjaa tänne tehdyn havainnon vaikutuksen terveyteen.',
        placeholder: 'Kirjoita terveydellinen merkitys tähän',
        buttonText: 'Tallenna terveydellinen merkitys'
      },
      image: {
        title: 'Kuvat',
        defaultText: 'Voit lisätä tänne osioon liittyviä kuvia. Kuvien enimmäismäärä on kolme.'
      },
      editComment: 'Muokkaa kommenttia: {0}',
      deleteComment: 'Poista kommentti: {0}',
      deleteConfirmation: 'Haluatko varmasti poistaa tämän kommentin?',
      emptyMessage: 'Tyhjä viesti'
    },
    summary: {
      title: 'Työterveyshuollon yhteenveto',
      description: 'Selvityksen toteutus, keskeiset tulokset ja seuranta',
      noContent:
        'Työterveyshuolto kirjaa tänne yhteenvedon työpaikan voimavaratekijöistä, vaaroista, kuormitustekijöistä ja erityistä sairastumisen vaaraa aiheuttavista tekijöistä.',
      placeholder: 'Kirjoita yhteenveto tähän',
      buttonText: 'Tallenna yhteenveto'
    },
    errors: {
      addMessage: 'Viestin lisääminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      modifyMessage: 'Viestin muokkaaminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      deleteMessage: 'Viestin poistaminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    }
  },

  riskManagementPreview: {
    print: 'Tulosta arviointi ja selvitys',
    edit: 'Muokkaus',
    preview: 'Esikatselu',
    occupationalHealthUnitPreview: 'Työterveyshuollon yhteenveto',
    additionalInfoHeading: 'Selvityksen toteutus, keskeiset tulokset ja seuranta',
    noAddedContent: 'Sisältöä ei ole lisätty',
    occupationalHealthUser: '(työterveyshuolto)'
  },

  documents: {
    instructions:
      'Voit lisätä tälle sivulle työpaikkaselvitykseen ja riskinarviointiin liittyviä dokumentteja. Kaikki yrityksen ja yrityksen kulloisenkin työterveyshuollon PIRA™-käyttäjät näkevät sivulle lisätyt dokumentit ja voivat lisätä ja poistaa niitä. Älä lisää mitään luottamuksellista ja arkaluontoista henkilötietoa, esimerkiksi terveystietoja, tai muuta salassa pidettävää tietoa. Tiedoston maksimikoko on 15 Mt. Palvelu tukee yleisimpiä teksti- ja kuvatiedostomuotoja.',
    mobileInstructions:
      'Kun käytät palvelua tietokoneella tai tabletilla, voit lisätä tälle sivulle työpaikkaselvitykseen ja riskinarviointiin liittyviä dokumentteja ja poistaa niitä.',
    addFile: 'Lisää tiedosto',
    addImage: 'Lisää kuva',
    added: 'Tiedosto lisätty',
    errors: {
      fileSizeTooBig:
        'Tiedoston lisääminen epäonnistui, koska tiedoston koko on liian suuri. Tiedoston enimmäiskoko on 15 Mt.',
      virusFound:
        'Tiedostossa saattaa olla virusongelma, minkä vuoksi tiedoston lisääminen epäonnistui. Ota yhteyttä organisaatiosi IT-tukeen.',
      notAcceptableMediaType:
        'Tiedoston lisääminen epäonnistui. Sallittuja tiedostomuotoja ovat: gif, jpeg, jpg, png, tiff, tif, bmp, doc, dot, dotx, docm, docx, ppt, ppa, pptx, potx, xls, xlsx, xlt, xla, txt, csv, ods, ott, odp, otp, ods, ots, pdf.',
      notAcceptableImageType:
        'Kuvan lisääminen epäonnistui. Sallittuja tiedostomuotoja ovat: gif, jpeg, jpg, png ja bmp.',
      deleteFile: 'Tiedoston poistaminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    deleteFile: 'Poista',
    deleteFileWithName: 'Poista tiedosto {0}',
    deleteConfirmation: 'Haluatko varmasti poistaa tiedoston {0}?',
    deleteObservationImageConfirmation:
      'Haluatko varmasti poistaa tiedoston {0}?<br /><br />Tiedoston poistaminen tästä näkymästä poistaa sen myös muokkausnäkymästä.',
    deleted: 'Tiedosto poistettu',
    noImages: 'Ei kuvia'
  },
  fileTable: {
    filename: 'Nimi',
    createdAt: 'Lisätty',
    createdBy: 'Lisännyt',
    delete: 'Poista',
    filterPlaceholder: 'Hae tiedostoa',
    noFiles: 'Ei lisättyjä dokumentteja.'
  },
  cookie_page: {
    description:
      'Evästekäytännössä kerromme, miten käytämme evästeitä ja miten käyttäjä voi hallita ja valvoa evästeiden käyttöä.',
    content_header1: 'Mikä on eväste?',
    content_header2: 'Mihin käytämme evästeitä?',
    content_header3: 'Työterveyslaitoksen käyttämät evästeet',
    content_header4: 'Käytämme seuraaviin luokkiin kuuluvia evästeitä',
    content_header5: 'Evästeiden estäminen ja poistaminen',
    content_subheader1: 'Välttämättömat evästeet',
    content_subheader2: 'Toiminnalliset evästeet',
    content_subheader3: 'Tilastolliset evästeet',
    content_subheader4: 'Markkinointievästeet',
    content_subheader5: 'Kolmannen osapuolen evästeet',
    content_subheader6: 'Muuttamalla evästeasetuksia sivun alareunan painikkeesta',
    content_subheader7: 'Muuttamalla selaimen evästeasetuksia',
    content_text1:
      'Verkkosivustollamme käytetään palvelun parantamiseksi evästeitä (eng. Cookies). Eväste on pieni tekstitiedosto, joka lähetetään käyttäjän päätelaitteelle, kun verkkosivustolla vieraillaan. Evästeet eivät vahingoita käyttäjien tietokoneita tai tiedostoja.',
    content_text2:
      'Käytämme evästeitä parantamaan verkkopalveluidemme käyttöä. Evästeiden avulla keräämme tietoa sivustojemme käytöstä, verkkosivustoilla vierailevien käyttäjien päätelaitteesta ja verkkoselaimesta. Käytämme evästeitä analytiikkaan työterveyslaitoksen verkkosivuilla. Evästeiden avulla sivuston käyttö on sinulle helpompaa ja näkemäsi markkinointi on tarkoituksenmukaisempaa.',
    content_text3:
      'Evästeet voidaan jaotella keston tai käyttötarkoituksen mukaan. Istuntokohtaiset evästeet toimivat selaimen käynnistämishetkestä sen sulkemiseen asti. Kun käyttäjä sulkee selaimen, evästeet poistetaan automaattisesti. Pysyvillä evästeillä on ennalta määritetty käyttöikä ja ne säilyvät selaimessa, kunnes ne vanhenevat tai käyttäjä poistaa ne itse.',
    content_text4:
      'Välttämättömät evästeet ovat tärkeitä verkkosivustomme perustoiminnoille: ominaisuuksille ja teknisille toiminnoille, joita ilman verkkosivustomme ei toimi. Näitä evästeitä ei voi poistaa käytöstä.',
    content_text5:
      'Toiminnallisten evästeiden avulla verkkosivustomme muistaa aiemmin tehdyt valinnat, kuten käyttäjänimen, kielen tai alueen. Näiden evästeiden avulla voimme tallentaa tietoja, jotka muuttavat verkkosivustomme toimivuutta.',
    content_text6:
      'Tilastollisten evästeiden avulla seuraamme verkkosivustomme kävijöiden toimintaa ja teemme analyyseja, joiden avulla voimme optimoida verkkosivustoamme. Tämä tarkoittaa, että voimme kehittää sivustoamme toimivammaksi ja palvelemaan kävijöitä paremmin. Jos et hyväksy näitä evästeitä, emme tiedä, milloin olet käynyt sivustollamme. Voit kuitenkin käyttää sivustoamme normaalisti.',
    content_text7:
      'Markkinointievästeiden avulla voimme kohdentaa sinulle mainoksia eri verkkosivustoilla tai sisältöä sosiaalisessa mediassa. Näitä evästeitä asettavat kolmannen osapuolen mainonta- ja kohdistustyökalut. Evästeiden kautta nämä toimijat voivat muodostaa profiilin kiinnostuksesi kohteista. Jos et hyväksy näitä evästeitä, et näe kohdennettua markkinointia etkä mahdollisesti voi käyttää sosiaalisen median jakotyökaluja.',
    content_text8:
      'Verkkosivustoillamme käytetään kolmannen osapuolen evästeitä. Kolmannen osapuolen evästeitä hyödynnetään muun muassa analyysi- tai markkinoinnin automatiikka -työkaluissa. Käyttäjän tulee tarkistaa kolmannen osapuolen palvelun yksityisyyttä ja evästeitä koskevista käytännöistä, miten kolmas osapuoli käyttää evästeitä.',
    content_text9:
      'Verkkosivustoillamme on lisäksi käytössä ns. Yhteisöliitännäisiä, joiden avulla käyttäjä voi jakaa sivuston sisältöä sosiaalisessa mediassa. Nämä palvelut voivat asettaa evästeitä omien yksityisyyden suojaa koskevien ehtojensa mukaisesti.',
    content_text10: 'Voit hallinnoida verkkosivustomme evästeitä kahdella tavalla.',
    content_text11:
      'Voit hallinnoida toiminnallisia, tilastollisia ja markkinointievästeitä sivun alareunan painikkeesta. Näitä evästeitä ei käytetä, jollet ole antanut niihin suostumusta. Ilman suostumusta estetään toiminnallisia, tilastollisia ja markkinointievästeitä käyttävien työkalujen toiminnat, etkä välttämättä pysty käyttämään kaikkea verkkosivustollamme olevaa sisältöä. Et näe myöskään sinulle kohdistettua markkinointia. Voit muuttaa näistä evästeasetuksia milloin tahansa kyseisestä painikkeesta.',
    content_text12:
      'Voit halutessasi estää evästeiden toiminnan muuttamalla omia selainasetuksiaan jolloin on mahdollista estää kaikki evästeet. Ohjeet löytyvät selaimen ohjesivulta. Mobiililaitteiden selaimet eivät aina tarjoa tätä mahdollisuutta, ja tällöin tulee tehdä muutoksia puhelimen asetuksiin. Käyttäjän tulee kuitenkin huomioida, että evästeiden käytön estäminen tai niiden poistaminen voi vaikuttaa haitallisesti verkkosivujen tai sen tiettyjen osioiden tai toimintojen käyttämiseen tai jopa estää sen kokonaan.',
    content_text13:
      'Lisätietoa selainkäyttöön perustuvan mainonnan toimintaperiaatteista, evästeistä ja keinoista suojata yksityisyyttä internetissä löytyy osoitteesta: www.youronlinechoices.eu.'
  },

  // Generic form messages
  form: {
    cancel: 'Peruuta',
    clear: 'Tyhjennä',
    delete: 'Poista',
    save: 'Tallenna',
    edit: 'Muokkaa',
    errors: {
      fieldRequired: 'Pakollinen tieto',
      minLength: 'Kentän vähimmäispituus on {0} merkkiä',
      maxLength: 'Kentän enimmäispituus on {0} merkkiä',
      passwordsMustMatch: 'Salasanat eivät täsmää',
      numericOnly: 'Anna vain numeroita',
      phoneNumber: 'Puhelinnumeron muoto ei kelpaa',
      password: 'Salasanan muoto ei kelpaa. Salasanan pituuden on oltava 8-64 merkkiä.'
    }
  },

  // Generic error messages
  error: {
    invalidAccount: 'Olet kirjautunut tunnuksella, jolla ei ole oikeutta käyttää PIRA™-palvelua.',
    browserNotSupported:
      'Käytät vanhentunutta selainta, jolla palvelu ei välttämättä toimi. Vaihda toiseen selaimeen (Mozilla Firefox, Google Chrome tai Internet Explorer versio 11 tai uudempi) - tai ota yhteyttä yrityksesi tekniseen tukeen.',
    browserNotSupportedSv:
      'Du använder en gammal webbläsare, med vilken Kvalitetsportalen inte nödvändigtvis fungerar. Byt/uppdatera din webbläsare (Mozilla Firefox, Google Chrome eller Internet Explorer version 11 eller nyare) - eller ta kontakt med din organisations tekniska stöd.',
    sessionOld: {
      title: 'Sessio on vanhentunut.',
      body: 'Kirjaudu sisään uudelleen.',
      button: 'Kirjaudu sisään'
    },
    generic: {
      title: 'Voi ei!',
      body: 'Jokin meni vikaan.',
      button: 'Yritä uudelleen'
    },
    forbidden: {
      title: 'Pääsy estetty',
      body: 'Sinulta puuttuu tarvittava käyttöoikeus.',
      button: 'Etusivulle'
    }
  },

  // Assistance texts for questions
  // Rest of the text are in db
  assistanceTexts: {
    summary:
      'Milloin työpaikkaselvitys tehtiin? Ketkä osallistuivat sen tekoon? Millainen yritys on kyseessä? Mitkä ovat työpaikan voimavaratekijät? Mitkä ovat merkittävimmät kuormitustekijät, vaaratekijät ja altisteet? Mitkä ovat työpaikan erityistä sairastumisen vaaraa aiheuttavat tekijät? Miten toimenpiteiden toteutumista seurataan?',
    infoLinkText: 'Työterveyslaitoksen sivuilta.'
  }
}
