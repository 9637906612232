import { client } from './client'

export const uploadDocument = (organizationId, fileData) =>
  client.post(`/api/pira/smes/${organizationId}/files`, fileData)

export const uploadObservationFile = (organizationId, riskFactorId, fileData) => {
  client.post(
    `/api/pira/organization/${organizationId}/riskmanagement/riskfactor/${riskFactorId}/file`,
    fileData
  )
}

export const getDocumentList = (organizationId) =>
  client.get(`/api/pira/smes/${organizationId}/files`)

export const deleteDocument = (organizationId, fileId) =>
  client.delete(`/api/pira/smes/${organizationId}/files/${fileId}`)
