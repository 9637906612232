<template>
  <div class="blocked">
    <div class="translation">{{ $t('error.browserNotSupported') }}</div>
    <div class="translation">{{ $t('error.browserNotSupportedSv') }}</div>
  </div>
</template>
<script>
export default {
  name: 'AppBlocked'
}
</script>
<style scoped lang="scss">
.blocked {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.translation + .translation {
  margin-top: 1rem;
}
</style>
