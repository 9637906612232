import '@mdi/font/css/materialdesignicons.css'

import { createVuetify } from 'vuetify'
import { VDataTableServer, VDataTable } from 'vuetify/labs/VDataTable'

const lightTheme = {
  dark: false,
  colors: {
    background: '#edf1f5',
    primary: '#356291'
  }
}

const vuetify = createVuetify({
  components: {
    VDataTable,
    VDataTableServer
  },
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})

export default vuetify
