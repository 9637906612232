<template>
  <router-link v-slot="{ isActive, isExactActive, navigate }" :exact="exact" :to="to">
    <span
      :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
      :aria-current="isExactActive ? 'page' : undefined"
      @click="handleClick(navigate, $event)"
    >
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'AccessibleLink',
  props: {
    to: {
      type: [String, Object],
      required: true
    },
    exact: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleClick(navigate, event) {
      this.$emit('click') && navigate(event)
    }
  }
}
</script>
