export const OCCUPATIONAL_HEALTH_USER = 'PIRA_ADMIN'
export const ORGANIZATION_USER = 'PIRA_ORGANIZATION_ADMIN'
export const ALLOWED_ROLES = [ORGANIZATION_USER, OCCUPATIONAL_HEALTH_USER]

export const RISK_FACTOR_STATUSES = {
  RISK_EXIST: 'RISK_EXIST',
  NO_RISKS: 'NO_RISKS',
  IN_ORDER: 'IN_ORDER',
  REQUIRES_FOLLOW_UP: 'REQUIRES_FOLLOW_UP',
  REQUIRES_IMPROVEMENT: 'REQUIRES_IMPROVEMENT'
}

export const OBSERVATION_MESSAGE_TYPES = {
  actionProposal: 'ACTION_PROPOSAL',
  observation: 'OBSERVATION',
  healthImplication: 'HEALTH_IMPLICATION',
  image: 'IMAGE'
}

export const LOADING_TIMEOUT = 300

export const ERROR_ACTIONS = {
  ADD_OBSERVATION_MESSAGE: 'ADD_OBSERVATION_MESSAGE',
  ADD_ADDITIONAL_INFO: 'ADD_ADDITIONAL_INFO'
}

export const MESSAGE_LENGTH_FOR_LABEL = 100

const config = window.APP_CONFIG

export const APP_BASE_URL = config?.appBaseUrl ?? import.meta.env.VITE_APP_BASE_URL
export const BUS_BASE_URL = config?.busBaseUrl ?? import.meta.env.VITE_BUS_BASE_URL
export const COOKIE_BASE_PRO_URL = config?.cookieproUrl ?? import.meta.env.VITE_COOKIE_BASE_PRO_URL

export const env = config?.env ?? import.meta.env.VITE_ENV
