import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faFloppyDisk,
  faBars,
  faPlus,
  faFaceSmile,
  faFaceMeh,
  faFaceFrown,
  faTrashCan,
  faXmark,
  faChevronRight,
  faChevronDown,
  faCheck,
  faPencil,
  faTriangleExclamation,
  faStethoscope,
  faWrench,
  faCamera,
  faClock,
  faBan
} from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faFaceSadTear } from '@fortawesome/free-regular-svg-icons'
import VueAnnouncer from '@vue-a11y/announcer'
import '@vue-a11y/announcer/dist/style.css'
import Vue3linkify from 'vue-3-linkify'

import App from './components/App.vue'
import router from './router'
import fi from './locales/fi'
import vuetify from './plugins/vuetify'

const i18n = createI18n({
  locale: 'fi',
  fallbackLocale: 'fi',
  messages: {
    fi
  },
  warnHtmlInMessage: 'off'
})

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(vuetify)
app.use(VueAnnouncer)
app.use(Vue3linkify)

library.add(faFloppyDisk)
library.add(faCalendar)
library.add(faBars)
library.add(faPlus)
library.add(faFaceSmile)
library.add(faFaceMeh)
library.add(faFaceFrown)
library.add(faTrashCan)
library.add(faXmark)
library.add(faChevronRight)
library.add(faChevronDown)
library.add(faCheck)
library.add(faPencil)
library.add(faTriangleExclamation)
library.add(faStethoscope)
library.add(faWrench)
library.add(faCamera)
library.add(faClock)
library.add(faBan)
library.add(faFaceSadTear)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
