import { EventBus } from '../../bus'

const focusableSelector =
  'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'

/**
 * A mixin that when used, listens to the set-focus event from EventBus
 * and when such event is called for this component, moves focus to the first
 * visible, enabled and focusable element in the component or alternatively calls an overriden
 * custom setFocus method.
 *
 * Easiest example:
 *  EventBus.$emit('set-focus', this) -> ask that focus is given to this component
 */
export default {
  beforeMount() {
    EventBus.$on('set-focus', this.focusHandler)
  },
  beforeUnmount() {
    EventBus.$off('set-focus', this.focusHandler)
  },
  methods: {
    focusHandler(elem) {
      elem === this && this.$nextTick(this.setFocus)
    },
    setFocus() {
      const focusable = Array.from(this.$el.querySelectorAll(focusableSelector))
      const nextVisible = focusable.find((el) => !el.disabled && el.offsetParent !== null)
      nextVisible && nextVisible.focus()
    }
  }
}
