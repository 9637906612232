import * as R from 'ramda'
import { getLastContentAddedTimes, getOrganizationInfo } from '@/api/organization'
import { getSmeOfficesForOccupationalHealthUnit, reduceOffices } from '@/api/user'
import { OCCUPATIONAL_HEALTH_USER, ORGANIZATION_USER } from '@/constants'
import { defineStore } from 'pinia'

const userHasRole = (user, role) => R.pathOr([], ['roles'], user).some((item) => item.role === role)

const getUserDirectSmeOffices = (user) => {
  const smeOfficeIds = R.compose(R.pluck('organization'), (data) =>
    data.roles.filter((role) => role.role === ORGANIZATION_USER)
  )(user)
  return Promise.all(smeOfficeIds.map((id) => getOrganizationInfo(id)))
}

const filterOnlyOwnSmeOffices = (state) =>
  Object.values(state.smeOffices).filter((smeOffice) => {
    return smeOffice.authorizationType === 'SME'
  })

export const useSmeOfficeStore = defineStore('smeOffice', {
  state: () => ({
    smeOffices: {},
    loading: false,
    currentSmeOfficeId: undefined,
    totalOfficesAmount: 0
  }),
  getters: {
    getSmeOffices: (state) => state.smeOffices,
    getOccupationalHealthSmeOffices: (state) => {
      return Object.values(state.smeOffices).filter(
        (smeOffice) => smeOffice.authorizationType === 'TTH'
      )
    },
    getOwnSmeOffices: (state) => filterOnlyOwnSmeOffices(state),
    getIsSmeDataLoading: (state) => state.loading,
    getCurrentSmeOfficeId: (state) => state.currentSmeOfficeId,
    getTotalOfficesAmount: (state) => state.totalOfficesAmount,
    getSmeOfficeName: (state) => (smeOfficeId) => {
      return R.pathOr('', ['smeOffices', smeOfficeId, 'name'], state)
    },
    getSmeOccupationalHealthUnitName: (state) => (smeOfficeId) => {
      const piraName = R.path(
        ['smeOffices', smeOfficeId, 'occupationalHealthUnit', 'piraName'],
        state
      )
      if (piraName) {
        return piraName
      } else {
        return R.pathOr('', ['smeOffices', smeOfficeId, 'occupationalHealthUnit', 'name'], state)
      }
    }
  },
  actions: {
    updateLoadingValue(newValue) {
      this.loading = newValue
    },
    setTotalOfficesAmount(amount) {
      this.totalOfficesAmount = amount
    },
    resetSmeOffices() {
      this.smeOffices = {}
    },
    upsertSmeOffice(smeOffice) {
      this.smeOffices[smeOffice.id] = smeOffice
    },
    updateCurrentSme(newValue) {
      this.currentSmeOfficeId = newValue
    },
    setOccupationalSmeOffices(smeOfficeData) {
      smeOfficeData.forEach((element) => {
        this.smeOffices[element.id] = { ...element, authorizationType: 'TTH' }
      })
    },
    async updateSmeOfficeData(payload) {
      const user = Object.prototype.hasOwnProperty.call(payload, 'user') ? payload.user : null
      const filters = Object.prototype.hasOwnProperty.call(payload, 'filters')
        ? payload.filters
        : {}
      if (user !== null) {
        this.updateLoadingValue(true)
        const smeUpdatePromises = []
        if (userHasRole(user, OCCUPATIONAL_HEALTH_USER)) {
          // If user has any TTH rights
          const response = await getSmeOfficesForOccupationalHealthUnit(filters)
          const totalOfficesAmount = response[0].organizations.itemsLength
          const smeOffices = reduceOffices(response)
          this.setTotalOfficesAmount(totalOfficesAmount)
          smeUpdatePromises.push(this.getTotalOfficesAmount)
          await getLastContentAddedTimes(
            smeOffices.map((org) => org.id),
            filters
          )
            .catch(() => [])
            .then((data) => {
              smeOffices.forEach((sme) => (sme.lastContentAddedBySme = data[sme.id]))
              this.resetSmeOffices()
              smeUpdatePromises.push(this.getSmeOffices)
              this.setOccupationalSmeOffices(smeOffices)
              smeUpdatePromises.push(this.getSmeOffices)
            })
        }
        if (userHasRole(user, ORGANIZATION_USER)) {
          // If user has any direct rights
          const userDirectSmeOffices = getUserDirectSmeOffices(user).then((data) =>
            data.forEach((smeOfficeResponse) =>
              this.upsertSmeOffice({
                ...smeOfficeResponse.data,
                authorizationType: 'SME'
              })
            )
          )
          smeUpdatePromises.push(userDirectSmeOffices)
        }
        return Promise.all(smeUpdatePromises)
          .then(() => this.updateLoadingValue(false))
          .then(() => {
            // if only 1 sme organization, its set to current, because nothing else can be selected
            if (this.getOwnSmeOffices.length === 1 && !this.getCurrentSmeOfficeId) {
              this.updateCurrentSme(this.getOwnSmeOffices[0].id)
            }
          })
      }
    }
  }
})
