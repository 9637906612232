import { client } from './client'

export const getUser = () => client.get('/user').then(({ data }) => (data ? data.user : null))

export const getSmeOfficesForOccupationalHealthUnit = (filters) =>
  client.post('/api/pira/occupational-health-unit/organizations', filters).then(({ data }) => data)

export const reduceOffices = (data) => data.reduce(getOfficesFromData, []).map(mapSmeOfficeObject)

const getOfficesFromData = (accumulator, currentValue) => {
  const ohuEnrichedOrgs = addOccupationalHealthUnit(
    currentValue.id,
    currentValue.name,
    currentValue.piraName,
    currentValue.organizations
  )
  return accumulator.concat(ohuEnrichedOrgs)
}

const mapSmeOfficeObject = (officeData) => {
  return {
    name: officeData.name,
    id: officeData.id,
    businessId: officeData.businessId,
    address: officeData.address,
    city: officeData.postOffice,
    occupationalHealthUnit: officeData.occupationalHealthUnit,
    lastContentAddedBySme: null // Fetched separately
  }
}

/*
 * Returns list of organizations with occupational health unit information in the organization the same way as
 * backend API returns, when fetching organizations.
 *
 * @param id            of the occupational health unit to be added to organizations.
 * @param name          of the occupational health unit to be added to organizations.
 * @param piraName      of the occupational health unit to be added to organizations.
 * @param organizations to be enriched with the occupational health unit information.
 * @return array of organizations.
 **/
const addOccupationalHealthUnit = (id, name, piraName, organizations) => {
  return organizations.items.map((org) => ({
    ...org,
    occupationalHealthUnit: { id: id, name: name, piraName: piraName }
  }))
}
