import { BUS_BASE_URL } from '@/constants'
import { displayGenericError } from '@/plugins/generic-error'

const getConfig = (config = {}, body) => {
  const isFormData = typeof FormData === 'function' && body instanceof FormData
  return {
    method: config.method,
    credentials: 'include',
    ...(body && { body: isFormData ? body : JSON.stringify(body) }),
    headers: {
      ...(body && !isFormData && { 'Content-Type': 'application/json' }),
      ...config.headers
    }
  }
}

const queryStr = (params) => {
  const queryStr = `${new URLSearchParams(params)}`
  return params && `?${queryStr}`
}

const defaultCallback = async (r) => {
  let data = await r.text()
  try {
    data = JSON.parse(data)
  } catch {
    /* empty */
  }
  return { data, status: r.status, headers: r.headers }
}

const http = (url, config, body) => {
  const mergedConfig = getConfig(config, body)
  return fetch([BUS_BASE_URL, url, queryStr(config.query)].join(''), mergedConfig).then((r) => {
    if (400 === r.status || 401 === r.status || 403 === r.status) {
      const error = new Error(r.statusText)
      error.code = r.status
      return displayGenericError(error)
    } else if (!r.ok) {
      return Promise.reject(Object.assign(new Error(r.statusText), { restError: true }))
    }
    return config.callback ? config.callback(r) : defaultCallback(r)
  })
}

const client = {
  get: (url, config = {}) => {
    return http(url, { ...config, method: 'GET' })
  },
  post: (url, body, config = {}) => {
    return http(url, { ...config, method: 'POST' }, body)
  },
  put: (url, body, config = {}) => {
    return http(url, { ...config, method: 'PUT' }, body)
  },
  patch: (url, body, config = {}) => {
    return http(url, { ...config, method: 'PATCH' }, body)
  },
  delete: (url, config = {}) => {
    return http(url, { ...config, method: 'DELETE' })
  }
}

export { client }

window.addEventListener('unhandledrejection', function (error) {
  if (error.reason.restError) {
    return displayGenericError(error)
  }
})
