import { client } from './client'

export const getRiskManagementData = (organizationId) =>
  client.get(`/api/pira/organization/${organizationId}/riskmanagement`)

export const getRiskManagementPreview = (organizationId) =>
  client.get(`/api/pira/organization/${organizationId}/riskmanagement/preview`)

export const putRiskManagementStatus = (organizationId, riskFactorId, status) =>
  client.put(
    `/api/pira/organization/${organizationId}/riskmanagement/riskfactor/${riskFactorId}/status`,
    { status }
  )

export const getObservationsWithMessagesForRiskFactor = (organizationId, riskFactorId) =>
  client.get(
    `/api/pira/organization/${organizationId}/riskmanagement/riskfactor/${riskFactorId}/observations`
  )

export const getObservation = (observationId, organizationId) =>
  client.get(
    `/api/pira/organization/${organizationId}/riskmanagement/riskfactor/observation/${observationId}`
  )

export const getNewRiskFactorMessages = (organizationId, timestamp) =>
  client.get(
    `/api/pira/organization/${organizationId}/riskmanagement/new-message-counts/timestamp/${timestamp}`
  )
