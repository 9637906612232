import router from '@/router'
import { useGenericErrorStore } from '@/store/genericErrorStore'

export const displayGenericError = (error) => {
  const genericErrorStore = useGenericErrorStore()

  const loginFn = () => {
    genericErrorStore.deleteErrorMessage()
    router.push({ name: 'login' })
  }

  const toFrontPage = () => {
    genericErrorStore.deleteErrorMessage()
    router.push({ name: 'frontpage' })
  }

  const closeFn = () => genericErrorStore.deleteErrorMessage()

  if (genericErrorStore.isShowErrorMessage) {
    return
  }

  const errorMap = {
    401: { translationPrefix: 'error.sessionOld', iconClass: ['fas', 'clock'], clickFn: loginFn },
    403: { translationPrefix: 'error.forbidden', iconClass: ['fas', 'ban'], clickFn: toFrontPage },
    500: {
      translationPrefix: 'error.generic',
      iconClass: ['far', 'face-sad-tear'],
      clickFn: closeFn
    }
  }
  const errorOptions =
    error && Object.prototype.hasOwnProperty.call(errorMap, error.code)
      ? errorMap[error.code]
      : errorMap[500]
  genericErrorStore.addErrorMessage({
    translationPrefix: errorOptions.translationPrefix,
    iconClass: errorOptions.iconClass,
    clickFn: errorOptions.clickFn
  })
}
