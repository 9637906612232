<template>
  <footer class="hide-in-print">
    <div class="footer__column">
      <a
        :aria-label="$t('footer.fioh')"
        :title="$t('footer.fioh')"
        href="http://www.ttl.fi"
        target="_blank"
        class="ttl-logo"
      />
      <p v-html="$t('footer.address')" />
    </div>
    <div class="footer__column">
      <p class="footer__column__title">{{ $t('footer.pira') }}</p>
      <p>
        <a href="http://www.pira-palvelu.fi">www.pira-palvelu.fi</a><br />
        <a href="mailto:pira@ttl.fi">pira@ttl.fi</a>
      </p>
    </div>
    <div class="footer__column">
      <p class="footer__column__title">{{ $t('footer.termsOfService') }}</p>
      <p>
        <a
          href="https://www.ttl.fi/tyoterveyslaitoksen-sahkoisten-palveluiden-yleiset-ehdot/"
          target="_blank"
          >{{ $t('footer.generalTerms') }}</a
        ><br />
        <a
          href="https://www.ttl.fi/tyoterveyslaitoksen-sahkoisten-palveluiden-tiedonsuojauksen-periaatteet/"
          target="_blank"
          >{{ $t('footer.dataProtectionPrinciples') }}</a
        ><br />
        <a href="documents/PIRA-palvelun_erityisehdot.pdf" target="_blank">{{
          $t('footer.piraTerms')
        }}</a
        ><br />
        <a href="documents/PIRA-palvelun_tietosuojailmoitus.pdf" target="_blank">{{
          $t('footer.privacyStatement')
        }}</a
        ><br />
        <a href="documents/PIRA-palvelun_saavutettavuusseloste.pdf" target="_blank">{{
          $t('footer.accessibilityStatement')
        }}</a
        ><br />
        &copy; {{ $t('footer.fioh') }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PiraFooter'
}
</script>
