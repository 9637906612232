<template>
  <Teleport to="body">
    <v-dialog v-model="dialog" persistent width="auto" min-width="400">
      <v-card>
        <div class="generic-error-modal">
          <font-awesome-icon :icon="getErrorMessage.iconClass" class="error-icon" />
          <v-card-title class="text-h3">
            {{ $t(getErrorMessage.translationPrefix + '.title') }}
          </v-card-title>
          <v-card-text>
            {{ $t(getErrorMessage.translationPrefix + '.body') }}
          </v-card-text>
          <v-card-actions>
            <button class="button" @click="click()">
              {{ $t(getErrorMessage.translationPrefix + '.button') }}
            </button>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </Teleport>
</template>

<script>
import { mapState } from 'pinia'
import { useGenericErrorStore } from '@/store/genericErrorStore'

export default {
  name: 'GenericErrorModal',
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState(useGenericErrorStore, ['isShowErrorMessage', 'getErrorMessage'])
  },
  methods: {
    click() {
      this.dialog = false
      setTimeout(() => {
        if (this.getErrorMessage.clickFn) {
          this.getErrorMessage.clickFn()
        }
      }, 500)
    }
  },
  watch: {
    isShowErrorMessage(value) {
      this.dialog = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../sass/componentimport';

.generic-error-modal {
  padding-top: rem(30px);
  padding-bottom: rem(20px);
  text-align: center;

  h3 {
    margin-top: rem(20px);
  }

  p {
    @include regular(16px);
  }

  .button {
    margin-top: rem(30px);
  }

  .error-icon {
    color: $calypso;
    font-size: rem(60px);
  }
}

@include breakpoint(mobile) {
  .generic-error-modal {
    padding-top: rem(20px);
    padding-bottom: rem(15px);

    h3 {
      margin-top: rem(15px);
    }

    p {
      @include regular(14px);
    }

    .button {
      margin-top: rem(25px);
    }

    .error-icon {
      color: $calypso;
      font-size: rem(45px);
    }
  }
}
</style>
