import { client } from './client'

export const createAdditionalInfo = (organizationId, infoData) =>
  client.post(`/api/pira/organization/${organizationId}/riskmanagement/additionalinfo`, infoData)

export const deleteAdditionalInfo = (organizationId, infoId) =>
  client.delete(`/api/pira/organization/${organizationId}/riskmanagement/additionalinfo/${infoId}`)

export const patchAdditionalInfo = (organizationId, infoId, infoData) =>
  client.patch(
    `/api/pira/organization/${organizationId}/riskmanagement/additionalinfo/${infoId}`,
    infoData
  )
