import { defineStore } from 'pinia'
import {
  getRiskManagementData,
  getRiskManagementPreview,
  putRiskManagementStatus
} from '@/api/riskmanagement'
import { RISK_FACTOR_STATUSES } from '@/constants'
import {
  createAdditionalInfo,
  patchAdditionalInfo,
  deleteAdditionalInfo
} from '@/api/additionalinfo'
import { sortByCreatedAtDateAsc } from '@/utils/customsorts'

const defaultRiskFactorState = RISK_FACTOR_STATUSES.RISK_EXIST

const RISK_FILTERS_KEY = 'riskManagement.riskFilters'

const setDefaultsToRiskManagementData = (data) => {
  data
    .map((x) => x.riskFactors)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue))
    .map(
      (riskFactor) =>
        (riskFactor.riskFactorStatuses =
          riskFactor.riskFactorStatuses.length === 0
            ? [{ status: defaultRiskFactorState }]
            : riskFactor.riskFactorStatuses)
    )
}

const arrangeRiskSections = (a, b) => {
  return a.classificationId - b.classificationId
}

const findRiskFactorById = (state, id) => {
  return state.riskManagementData
    .map((x) => x.riskFactors)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue))
    .find((item) => item.id === id)
}

const getRiskFactorList = () => {
  return this.riskManagementData
    .map((x) => x.riskFactors)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue))
}

export const useRiskManagementStore = defineStore('riskManagement', {
  state: () => ({
    riskManagementData: [],
    riskFilters: null,
    additionalInfos: [],
    riskManagementPreview: [],
    loadingData: true,
    editedAdditionalInfo: undefined,
    loadingPreviewData: true
  }),
  getters: {
    getRiskSections: (state) => (state.loadingData ? [] : state.riskManagementData),
    getRiskSectionsSorted: (state) => {
      return state.loadingData ? [] : Array.from(state.riskManagementData).sort(arrangeRiskSections)
    },
    getRiskSectionsPreviewSorted: (state) => {
      return state.loadingPreviewData
        ? []
        : Array.from(state.riskManagementPreview.riskFactorGroups).sort(arrangeRiskSections)
    },
    getAdditionalInfoPreview: (state) => {
      return state.loadingPreviewData
        ? []
        : sortByCreatedAtDateAsc(state.riskManagementPreview.additionalInfos)
    },
    getRiskFiltersActive: (state) => !!state.riskFilters,
    getRiskFilters: (state) => state.riskFilters,
    getRiskFactors: (state) => (state.loadingData ? [] : getRiskFactorList(state)),
    getAdditionalInfos: (state) =>
      state.loadingData ? [] : sortByCreatedAtDateAsc(state.additionalInfos),
    getEditedAdditionalInfo: (state) => state.editedAdditionalInfo,
    infoIsEdited: (state) => !!state.editedAdditionalInfo,
    getRiskFactorById: (state) => (riskFactorId) => {
      return state.loadingData ? {} : findRiskFactorById(state, riskFactorId)
    }
  },
  actions: {
    changeLoadingState(value) {
      this.loadingData = value
    },
    setRiskManagementData(data) {
      this.riskManagementData = data
    },
    setAdditionalInfos(data) {
      this.additionalInfos = data
    },
    setRiskManagementPreview(data) {
      this.riskManagementPreview = data
    },
    addAdditionalInfo(data) {
      this.additionalInfos.push(data)
    },
    removeAdditionalInfoFromStore(infoId) {
      this.additionalInfos = this.additionalInfos.filter((info) => info.id !== infoId)
    },
    setEditedAdditionalInfo(info) {
      this.editedAdditionalInfo = info
    },
    updateRiskFactorStatus(data) {
      const riskFactor = findRiskFactorById(this, data.riskFactorId)
      riskFactor.riskFactorStatuses.unshift({
        status: data.status,
        createdAt: new Date().toISOString()
      })
    },
    updateAdditionalInfoToStore(additionalInfo) {
      this.additionalInfos.forEach((info, index) => {
        if (info.id === additionalInfo.id) {
          this.additionalInfos[index] = {
            ...info,
            text: additionalInfo.text,
            updatedAt: additionalInfo.updatedAt
          }
        }
      })
    },
    loadRiskFilters() {
      const data = JSON.parse(sessionStorage.getItem(RISK_FILTERS_KEY))
      if (!data) {
        return null
      }
      this.riskFilters = {
        ...data,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate)
      }
    },
    setRiskFilters(value) {
      this.riskFilters = value || null
      sessionStorage.setItem(RISK_FILTERS_KEY, JSON.stringify(value || null))
    },
    clearRiskFilters() {
      this.riskFilters = null
      sessionStorage.removeItem(RISK_FILTERS_KEY)
    },
    changePreviewLoadingState(value) {
      this.loadingPreviewData = value
    },
    async getRiskManagementData(officeId) {
      this.changeLoadingState(true)
      const result = await getRiskManagementData(officeId)
      const riskFactorGroups = result.data.riskFactorGroups
      setDefaultsToRiskManagementData(riskFactorGroups)
      this.setRiskManagementData(riskFactorGroups)
      this.setAdditionalInfos(result.data.additionalInfos)
      this.changeLoadingState(false)
    },
    async getRiskManagementPreview(officeId) {
      this.changePreviewLoadingState(true)
      const result = await getRiskManagementPreview(officeId)
      setDefaultsToRiskManagementData(result.data.riskFactorGroups)
      this.setRiskManagementPreview(result.data)
      this.changePreviewLoadingState(false)
    },
    upsertRiskFactorStatus(data) {
      putRiskManagementStatus(data.organizationId, data.riskFactorId, data.status).then(() =>
        this.updateRiskFactorStatus({ riskFactorId: data.riskFactorId, status: data.status })
      )
    },
    async createAdditionalInfo(data) {
      const response = await createAdditionalInfo(data.organizationId, { text: data.text })
      this.addAdditionalInfo(response.data)
    },
    async updateAdditionalInfo(data) {
      const response = await patchAdditionalInfo(data.organizationId, data.infoId, {
        text: data.text
      })
      this.updateAdditionalInfoToStore(response.data)
    },
    async removeAdditionalInfo(data) {
      await deleteAdditionalInfo(data.organizationId, data.infoId)
      this.removeAdditionalInfoFromStore(data.infoId)
    },
    setAdditionalInfoForEditing(infoData) {
      this.setEditedAdditionalInfo(infoData)
    }
  }
})
