import { client } from './client'

export const getOrganizationInfo = (organizationId) =>
  client.get(`/api/pira/organizations/${organizationId}`)

/**
 * Fetch time of last content update for organizations
 * @param {*} organizationIds UUIDs of organizations
 * @param {*} filters data table filters (current page, items per page, ...)
 * @returns {Object} Object with UUID as keys
 */
export const getLastContentAddedTimes = (organizationIds, filters) =>
  client
    .post('/api/pira/organizations/additional-info', { organizationIds, filters })
    .then(({ data }) =>
      data.reduce((accumulator, current) => {
        accumulator[current.organizationId] = current.lastContentAddedBySme
        return accumulator
      }, {})
    )
